import React, { useState } from "react";
import styled from "styled-components";
import { FaArrowUp } from "react-icons/fa";

const ScrollToTop = () => {
  const [scrollState, setScrollState] = useState(false);

  const toTop = () => {
    window.scrollTo({ top: 0 });
  };
  window.addEventListener("scroll", () => {
    window.pageYOffset > 200 ? setScrollState(true) : setScrollState(false);
  });

  return (
    <ToTop scrollState={scrollState} onClick={toTop}>
      <FaArrowUp className="scrollIcon" />
    </ToTop>
  );
};

const ToTop = styled.div`
  display: ${({ scrollState }) => (scrollState ? "block" : "none")};
  position: fixed;
  bottom: 1rem;
  right: 2rem;
  z-index: 10;
  cursor: pointer;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  background-color: var(--blue);
  .scrollIcon {
    width: 100%;
    height: 100%;
    color: var(--black);
  }
`;

export default ScrollToTop;
