import React from "react";
import styled from "styled-components";

const SingleSecurity = ({ item }) => {
  return (
    <SingleSecurityContainer>
      <SingleSecurityImgContainer>
        <img src={item.img} alt="Security" className="image" />
      </SingleSecurityImgContainer>
      <SingleSecurityTitle>{item.title.toUpperCase()}</SingleSecurityTitle>
      <SingleSecurityDesc>{item.desc}</SingleSecurityDesc>
    </SingleSecurityContainer>
  );
};

const SingleSecurityContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-height: 500px;
  justify-content: center;
  padding: 20px;
  border-radius: 10px;
  @media (max-width: 768px) {
    padding: 0 20px;
  }
`;

const SingleSecurityImgContainer = styled.div`
  border-radius: 50%;
  border: 2px solid #000;
  padding: 30px;
  img {
    height: 50px;
  }
`;

const SingleSecurityTitle = styled.h2`
  color: var(--white);
  text-align: center;
  font-weight: bold;
  @media screen and (max-width: 768px) {
    letter-spacing: 1.3px;
  }
`;

const SingleSecurityDesc = styled.div`
  margin: 1rem 0 auto;
  text-align: center;
  color: var(--white);
  font-weight: 400;
  line-height: 1.5;
  @media screen and (max-width: 768px) {
    max-width: 300px;
  }
`;

export default SingleSecurity;
