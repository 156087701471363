import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import styled from "styled-components";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Mousewheel } from "swiper";
import { services } from "../datas/services";

const Services = () => {
  return (
    <ServicesSection id="services">
      <ServicesTitle>SERVICES</ServicesTitle>
      <ServicesSwiper>
        <Swiper
          direction={"vertical"}
          slidesPerView={1}
          spaceBetween={30}
          mousewheel={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          loop={true}
          pagination={{
            clickable: true,
          }}
          modules={[Mousewheel, Pagination, Autoplay]}
          className="mySwiper"
        >
          {services.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <SwiperContainer>
                  <SwiperImageContainer
                    image={item.image}
                  ></SwiperImageContainer>
                  <SwiperInfo>
                    <SwiperTitle>{item.title}</SwiperTitle>
                    <SwiperText>{item.description}</SwiperText>
                  </SwiperInfo>
                </SwiperContainer>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </ServicesSwiper>
    </ServicesSection>
  );
};

const ServicesSection = styled.section`
  margin: 10px auto 150px auto;
  overflow-y: auto;
`;

const ServicesTitle = styled.h2`
  font-size: 48px;
  color: var(--blue);
  text-transform: uppercase;
  margin: 100px 0 50px 0;
  text-align: center;
  overflow-y: auto;
`;

const ServicesSwiper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  color: #fff;
  max-width: 1140px;

  @media (max-width: 900px) {
    padding: 10px;
  }
`;

const SwiperContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: 1px solid var(--blue);
  border-radius: 10px;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const SwiperInfo = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media (max-width: 768px) {
    margin-top: 20px;
  }
`;

const SwiperImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex: 1;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: ${({ image }) => `url(${image})`};
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  img {
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 600px) {
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0;
  }
`;

const SwiperTitle = styled.h2`
  color: var(--white);
  text-transform: uppercase;
`;

const SwiperText = styled.p`
  color: var(--white);
  font-weight: 400;
  max-width: 80%;
  margin: 20px auto;
  font-size: 18px;
  text-align: left;
`;

export default Services;
