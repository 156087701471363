export const services = [
  {
    id: 1,
    title: "COMMUNITY BUILDING & GROWTH",
    description:
      "We know community is the single most important asset of any Blockchain project. While many sub-par projects thrive, many more great projects suffer from lack of a community or end users. Huginn offers professional Community Building and organic Community Growth strategies and services with a team that comprises of experienced Brand Builders and Growth Strategists.",
    image: require("../assets/images/community.png"),
  },
  {
    id: 2,
    title: "PROFESSIONAL SUPPORT",
    description:
      "Whilst having a Community is essential, the effort doesn't end there. Keeping in contact with your community & end users, hearing their comments and resolving their problems is one of the key aspects for success. We offer a variety of professional support packages for projects on any desired platform (Twitter, Telegram, Discord, etc.), ranging from multiple staff and 24/7 support on multiple platforms, to part-time support on single platform.",
    image: require("../assets/images/support.png"),
  },
];
