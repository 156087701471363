import React from "react";
import styled from "styled-components";
import { Carousel } from "../components";
import { about } from "../datas/content.js";

const About = () => {
  return (
    <AboutSection id="about">
      <AboutContainer>
        <AboutBox>
          <Carousel />
        </AboutBox>
        <AboutBox>
          <AboutTitle>{about.title}</AboutTitle>
          <AboutSubText>{about.subtext}</AboutSubText>
          <AboutSubTextLight>
            {about.sublighttext}
            <AboutBtnContainer>
              <AboutButton href="#contact">Contact</AboutButton>
            </AboutBtnContainer>
          </AboutSubTextLight>
        </AboutBox>
      </AboutContainer>
    </AboutSection>
  );
};

const AboutSection = styled.section`
  min-height: 100vh;
  width: 100%;
  background-color: var(--black);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: var(--white);
`;

const AboutContainer = styled.div`
  width: 75%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  .swiper {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 64em) {
    width: 100%;
    flex-direction: column;

    & > *:last-child {
      width: 80%;
    }
  }
  @media (max-width: 70em) {
    width: 85%;
    flex-direction: column;

    & > *:last-child {
      width: 80%;
    }
  }

  @media (max-width: 40em) {
    & > *:last-child {
      width: 90%;
    }
  }
`;

const AboutBox = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  min-height: 60vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 40em) {
    min-height: 50vh;
  }
`;

const AboutTitle = styled.h2`
  text-transform: uppercase;
  width: 80%;
  color: var(--blue);
  font-size: 48px;
  align-self: flex-start;
  margin: 0 auto;

  @media (max-width: 64em) {
    width: 100%;
    text-align: center;
  }
`;

const AboutSubText = styled.p`
  width: 80%;
  color: var(--white);
  align-self: flex-start;
  font-size: 18px;
  margin: 1rem auto;
  font-weight: 400;
`;

const AboutSubTextLight = styled.p`
  width: 80%;
  font-size: 18px;
  color: #fff;
  align-self: flex-start;
  margin: 0 auto 1rem auto;
  font-weight: 400;
`;

const AboutBtnContainer = styled.div`
  width: "80%";
  align-self: flex-start;
  margin: 1rem auto;
  display: flex;

  @media (max-width: 64em) {
    width: 100%;

    button {
      margin: 0 auto;
    }
  }
`;

const AboutButton = styled.a`
  outline: none;
  font-size: 17px;
  padding: 15px 20px;
  border-radius: 10px;
  background-color: var(--blue);
  border: 1px solid var(--blue);
  color: var(--white);
  cursor: pointer;

  &:hover {
    background-color: inherit;
    border: 1px solid var(--blue);
    transition: 0.3s all ease-in-out;
  }
`;

export default About;
