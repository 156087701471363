export const security = [
  {
    id: 1,
    title: "24/7 live monitoring",
    desc: "Our servers are being monitored 24/7 with warning tools and an experienced team of IT Experts stands ready to intervene at all times.",
    img: require("../assets/images/monitoring.png"),
  },
  {
    id: 2,
    title: "Infrastructure",
    desc: "We use enterprise-grade servers with error-correcting memory, guaranteed power and data redundancy.",
    img: require("../assets/images/infra.png"),
  },
  {
    id: 3,
    title: "Protection",
    desc: "Layers of Private and Public sentries located around the globe ensure maximum uptime. We offer soft-slash protection and refund downtime slashing.",
    img: require("../assets/images/protection.png"),
  },
];
