import React from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { EffectCards, Pagination, Navigation, Autoplay } from "swiper";
import { about } from "../datas/about";

const Carousel = () => {
  return (
    <CarouselContainer>
      <Swiper
        effect={"cards"}
        grabCursor={true}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        pagination={{ type: "fraction" }}
        navigation={true}
        scrollbar={{ draggable: true }}
        modules={[EffectCards, Pagination, Navigation, Autoplay]}
        className="mySwiper"
      >
        {about.map((content, index) => {
          return (
            <SwiperSlide key={index}>
              {/* <img src={item.img} alt={item.title} /> */}
              <SwiperLinks href={content.link} target={"_blank"}>
                {content.title}
              </SwiperLinks>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </CarouselContainer>
  );
};

const CarouselContainer = styled.div`
  height: 70vh;
  width: 25vw;

  @media (max-width: 80em) {
    height: 60vh;
    width: 35vw;
  }

  @media (max-width: 70em) {
    height: 60vh;
    width: 40vw;
  }
  @media (max-width: 64em) {
    height: 50vh;
    width: 45vw;
  }
  @media (max-width: 48em) {
    height: 50vh;
    width: 55vw;
  }
  @media (max-width: 30em) {
    height: 45vh;
    width: 60vw;
  }
  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    background-color: var(--blue);
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-button-next {
    right: 0;

    @media (max-width: 64em) {
      width: 3rem;
    }

    @media (max-width: 30em) {
      width: 2rem;
    }
  }

  .swiper-button-reverse {
    color: var(--white);
    left: 0;
  }
`;

const SwiperLinks = styled.a`
  color: var(--white);
  font-size: 35px;
  margin: 0 auto;

  @media (max-width: 70em) {
    font-size: 25px;
  }

  @media (max-width: 48em) {
    font-size: 20px;
  }

  @media (max-width: 30em) {
    font-size: 15px;
  }
`;

export default Carousel;
