import styled from "styled-components";
import {
  //FaGithub,
  FaTelegram,
  FaDiscord,
  FaYoutube,
  FaTwitter,
  FaMedium,
} from "react-icons/fa";
import img from "../assets/new-icon.png";

const Contact = () => {
  const contacts = [
    {
      id: 1,
      name: "Twitter",
      href: "https://twitter.com/HuginnStake",
      component: <FaTwitter className="twitter" />,
    },
    {
      id: 2,
      name: "Youtube",
      href: "https://huginn.tv/",
      component: <FaYoutube className="youtube" />,
    },
    {
      id: 3,
      name: "Telegram",
      href: "https://t.me/HuginnStake",
      component: <FaTelegram className="telegram" />,
    },
    {
      id: 4,
      name: "Discord",
      href: "https://discord.gg/huginn",
      component: <FaDiscord className="discord" />,
    },
    {
      id: 5,
      name: "Blog",
      href: "https://blog.huginn.tech/",
      component: <FaMedium className="medium" />,
    },
  ];

  return (
    <ContactSection id="contact">
      <ContactContainer>
        <ContactForm>
          <ContactTitle>Contact</ContactTitle>
          <form
            className="contact"
            action="mailto:stake@huginn.tech"
            encType="text/plain"
            method="POST"
          >
            <ContactFormInputContainer>
              <input
                type="text"
                name="name"
                className="text-box"
                placeholder="Your Name"
                required
              />
              <input
                type="text"
                name="mail"
                className="text-box"
                placeholder="Your Email"
                required
              />
            </ContactFormInputContainer>
            <textarea
              name="comment"
              rows="5"
              placeholder="Your Message"
              required
            />
            {alert && (
              <AlertMessage
                type={`${alert.type === "error" ? "error" : "success"}`}
              >
                {alert.message}
              </AlertMessage>
            )}
            <input type="submit" className="send-btn" value="Send" />
          </form>
        </ContactForm>
        <ContactInfo>
          {contacts.map((item) => (
            <ContactSocial key={item.id} href={item.href} target={"_blank"}>
              {item.component}
              <div>{item.name}</div>
            </ContactSocial>
          ))}
        </ContactInfo>
        <ContactRight>
          <img src={img} alt="logo" />
          <p className="footer-text">&copy;Copyright 2022 Huginn Academy</p>
        </ContactRight>
      </ContactContainer>
    </ContactSection>
  );
};

const ContactSection = styled.footer`
  padding: 20px;
`;

const ContactTitle = styled.h2`
  font-size: 48px;
  color: var(--blue);
  text-transform: uppercase;
  margin: 20px 0 50px 0;
  text-align: flex-start;
`;

const ContactContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1140px;
  margin: 0 auto;

  @media (max-width: 950px) {
    flex-direction: column;
  }
`;

const ContactInfo = styled.div`
  max-width: 500px;
  line-height: 65px;
  padding-left: 50px;
  display: flex;
  align-items: flex-start;
  margin: 0 auto;
  flex-direction: column;
  justify-content: space-around;
  font-size: 18px;
  flex: 1;

  @media (min-width: 950px) {
    margin: 30px 50px;
  }
`;
const ContactSocial = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  text-decoration: none;
  color: var(--white);

  &:hover {
    text-decoration: underline;
  }

  div {
    margin-left: 5px;
  }

  @media (max-width: 950px) {
    div {
      margin-right: 15px;
    }
  }
`;

const ContactForm = styled.div`
  max-width: 700px;
  margin-right: 50px;
  flex: 3;

  .text-box {
    color: var(--black);
    width: 50%;
    height: 60px;
    padding: 12px;
    font-size: 18px;
    border-radius: 5px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    opacity: 0.9;
    outline: none;

    &:focus {
      border: 3px solid var(--blue);
    }

    &:first-child {
      margin-right: 15px;
    }

    @media (max-width: 768px) {
      &:first-child {
        margin-right: 0;
      }
    }
  }

  textarea {
    color: var(--black);
    border: none;
    width: 100%;
    padding: 12px;
    font-size: 18px;
    min-height: 200px;
    max-height: 400px;
    resize: vertical;
    border-radius: 5px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    opacity: 0.9;
    outline: none;

    &:focus {
      border: 3px solid var(--blue);
    }
  }

  .send-btn {
    float: left;
    background: var(--blue);
    color: #fff;
    border: none;
    width: 150px;
    height: 50px;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
    transition-property: var(--blue);

    &:hover {
      background: #39a3a3;
    }
  }

  @media (max-width: 950px) {
    margin: 30px 50px;

    h2 {
      font-size: 30px;
    }

    .text-box {
      width: 100%;
    }
  }
`;

const ContactFormInputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ContactRight = styled.div`
  flex: 1;
  width: 200px;
  height: 200px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  p {
    min-width: 350px;
    margin-top: 20px;
    font-weight: bold;
    color: var(--white);
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
`;

const AlertMessage = styled.div`
  margin-bottom: 10px;
  color: ${({ type }) => (type === "error" ? "#c83227" : "#45c4c4")};
  font-size: 18px;
  font-weight: bold;
`;

export default Contact;
