import React, { useRef, useEffect } from "react";
import img from "../assets/huginnicon.jpeg";
import styled from "styled-components";
import { FaWindowClose } from "react-icons/fa";

const ComingSoonPopup = ({ onClose }) => {
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  });

  const refOne = useRef(null);

  const handleClickOutside = (e) => {
    if (!refOne.current.contains(e.target)) {
      onClose();
    }
  };

  return (
    <PopupWrapper onClick={onClose} ref={refOne}>
      <PopupContainer
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="modalContainer"
      >
        <Img src={img} alt="/" />
        <PopupRight>
          <PopupClose onClick={onClose}>
            <FaWindowClose />
          </PopupClose>
          <PopupContent>
            <h1>Coming Soon!</h1>
            <p>We are working on this feature.</p>
          </PopupContent>
        </PopupRight>
      </PopupContainer>
    </PopupWrapper>
  );
};

const PopupWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99;
`;

const PopupContainer = styled.div`
  max-width: 500px;
  width: 100%;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  background-color: var(--white);
  border-radius: 8px;

  @media (max-width: 600px) {
    flex-direction: column;
    width: 80%;
  }
`;

const Img = styled.img`
  max-width: 200px;
  object-fit: cover;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  @media (max-width: 600px) {
    display: none;
  }
`;

const PopupRight = styled.div`
  width: 100%;
`;

const PopupClose = styled.div`
  position: fixed;
  top: 8px;
  right: 8px;
  font-size: 30px;
  cursor: pointer;
`;
const PopupContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-top: 3rem;
  padding: 1rem 2rem;
  @media (max-width: 600px) {
    margin-top: 2rem;
  }
`;

export default ComingSoonPopup;
