export const about = [
  {
    id: 1,
    title: "Huginn.Academy",
    link: "https://huginn.academy/",
  },
  {
    id: 2,
    title: "Huginn.Tv",
    link: "https://www.youtube.com/c/HuginnAcademy",
  },
  {
    id: 3,
    title: "Blog.Huginn.Tech",
    link: "https://blog.huginn.tech/",
  },
  {
    id: 4,
    title: "Huginn.News",
    link: "http://www.huginn.news/",
  },
  {
    id: 5,
    title: "Testnet.Huginn.Academy",
    link: "https://testnet.huginn.academy/",
  },
];
