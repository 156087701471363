import React from "react";
import { SingleSecurity } from "../components";
import { security } from "../datas/security";
import styled from "styled-components";

const Security = () => {
  return (
    <SecuritySection id="security">
      <SecurityTitle>SECURITY</SecurityTitle>
      <SecurityContainer>
        <SecurityList>
          {security.map((item, index) => {
            return <SingleSecurity item={item} key={index} />;
          })}
        </SecurityList>
      </SecurityContainer>
    </SecuritySection>
  );
};

const SecuritySection = styled.section`
  background-color: var(--black);
  margin: 80px auto;
  padding: 20px;
  overflow-y: auto;

  @media (max-width: 768px) {
    margin: auto;
  }
`;

const SecurityTitle = styled.h1`
  font-size: 48px;
  color: var(--blue);
  text-align: center;
  margin-top: 90px;

  @media (max-width: 500px) {
    font-size: 40px;
  }
`;

const SecurityContainer = styled.div`
  @media (min-width: 1200px) {
    max-width: 1300px;
    margin: auto;
  }
`;

const SecurityList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 1rem;
  grid-gap: 2rem;
  @media (max-width: 1100px) {
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 3rem;
  }
  @media (max-width: 568px) {
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: 20px;
  }
`;

export default Security;
