import React from "react";
import styled from "styled-components";
import { FaTimes } from "react-icons/fa";

const Sidebar = ({ isOpen, toggle, setOpenModal }) => {
  return (
    <SidebarWrapper isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarContainer>
        <SidebarList>
          <SidebarLink href="#networks">Networks</SidebarLink>
          <SidebarLink href="#security">Security</SidebarLink>
          <SidebarLink href="#services">Services</SidebarLink>
          <SidebarLink
            href="https://medium.com/huginnacademy"
            target={"_blank"}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div>Blog</div>{" "}
            <span
              className="material-symbols-outlined"
              style={{ fontSize: "18px" }}
            >
              open_in_new
            </span>
          </SidebarLink>
          <SidebarLink
            href="https://docs.huginn.tech/"
            target={"_blank"}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div>Docs</div>{" "}
            <span
              className="material-symbols-outlined"
              style={{ fontSize: "18px" }}
            >
              open_in_new
            </span>
          </SidebarLink>
          <SidebarLink href="#about">About</SidebarLink>
          <SidebarLink href="#contact">Contact</SidebarLink>
        </SidebarList>
        <SidebarBtnWrap>
          <SidebarRoute onClick={() => setOpenModal(true)}>
            Calculator
          </SidebarRoute>
        </SidebarBtnWrap>
      </SidebarContainer>
    </SidebarWrapper>
  );
};

const SidebarWrapper = styled.div`
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: #0d0d0d;
  display: grid;
  align-items: center;
  top: 0;
  left: 0;
  transition: 0.3s ease-in-out;
  opacity: ${({ isOpen }) => (isOpen ? "100%" : "0")};
  top: ${({ isOpen }) => (isOpen ? "0" : "-100%")};
`;

const Icon = styled.div`
  position: absolute;
  top: 1.2rem;
  right: 1.5rem;
  background: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
`;

const CloseIcon = styled(FaTimes)`
  color: var(--white);
`;

const SidebarContainer = styled.div`
  color: var(--white);
`;

const SidebarList = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(6, 80px);
  text-align: center;

  @media (max-width: 480px) {
    grid-template-rows: repeat(6, 60px);
  }
`;

export const SidebarLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  text-decoration: none;
  transition: 0.2s ease-in-out;
  text-decoration: none;
  color: var(--white);
  cursor: pointer;

  &:hover {
    color: var(--blue);
    transition: 0.2s ease-in-out;
  }
`;

const SidebarBtnWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
`;

const SidebarRoute = styled.button`
  border: none;
  outline: none;
  font-size: 15px;
  padding: 15px 20px;
  border-radius: 10px;
  background-color: var(--blue);
  border: 1px solid var(--blue);
  color: var(--white);

  &:hover {
    background-color: inherit;
    border: 1px solid var(--blue);
    transition: 0.3s all ease-in-out;
  }
`;

export default Sidebar;
