import { v4 as uuidv4 } from "uuid";
import { SingleProtocol } from "../components";
import { datas } from "../datas/protocols";
import styled from "styled-components";

const Protocols = () => {
  return (
    <ProtocolsSection id="networks">
      <ProtocolsTitle>Networks</ProtocolsTitle>
      <ProtocolsContainer>
        <ProtocolsList>
          {datas.map((item) => {
            return <SingleProtocol item={item} key={uuidv4(item)} />;
          })}
        </ProtocolsList>
      </ProtocolsContainer>
    </ProtocolsSection>
  );
};

const ProtocolsSection = styled.section`
  overflow-y: auto;
`;

const ProtocolsTitle = styled.h1`
  font-size: 48px;
  color: var(--blue);
  text-align: center;
  margin: 100px 0 40px 0;
  text-transform: uppercase;

  @media (max-width: 500px) {
    font-size: 40px;
  }
`;

const ProtocolsContainer = styled.div`
  @media (min-width: 1200px) {
    max-width: 1300px;
    margin: auto;
  }
`;

const ProtocolsList = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  padding: 0 30px 50px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 1200px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export default Protocols;
