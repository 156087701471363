import React from "react";
import styled from "styled-components";

const SingleProtocol = ({ item }) => {
  const { title, img, mainnet, src, radius } = item;

  return (
    <SingleProtocolContainer>
      <SingleProtocolImgContainer radius={radius}>
        <img src={img} alt="protocol" className="image" />
      </SingleProtocolImgContainer>

      <SingleProtocolInfo>
        <h2>{title.toUpperCase()}</h2>
        <SingleProtocolType>
          <h4>{mainnet ? "Mainnet" : "Testnet"}</h4>
        </SingleProtocolType>
        {/* <h2></h2> */}
        <p></p>
        {/* <h2>{apr ? `${apr}%` : ""}</h2>
        <p>{apr ? "Apr" : ""}</p> */}
      </SingleProtocolInfo>

      <SingleProtocolBtn href={src} target={"/blank"} src={src}>
        {src === "" ? "Soon" : "Stake Now"}
      </SingleProtocolBtn>
    </SingleProtocolContainer>
  );
};

const SingleProtocolContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--black);
  min-height: 300px;
  justify-content: center;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid var(--blue);
  align-self: normal;
  &:hover {
    box-shadow: 0 5px 15px var(--blue);
    transition: 0.3s all ease-in-out;
    img {
      transform: scale(1.1);
      transition: 0.3s all ease-in-out;
    }
  }
`;

const SingleProtocolImgContainer = styled.div`
  min-height: 150px;
  img {
    height: 150px;
    border-radius: ${({ radius }) => (radius ? "50%" : "10px")};
  }
`;

const SingleProtocolInfo = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  h2:first-child {
    text-align: center;
    color: var(--white);
  }

  p {
    text-align: center;
    color: var(--blue);
    line-height: 1.6;
    text-transform: uppercase;
    min-height: 20px;
  }
  h3 {
    margin-top: 10px;
    color: var(--blue);
  }

  h2 {
    text-align: center;
    color: var(--blue);
    margin: 10px auto 0 auto;
    min-height: 35px;
  }
`;

const SingleProtocolBtn = styled.a`
  display: inline-block;
  text-align: center;
  cursor: pointer;
  width: 100%;
  padding: 10px;
  font-size: 20px;
  background-color: var(--blue);
  color: var(--white);
  border-radius: 10px;
  margin-top: auto;
  pointer-events: ${({ src }) => (src === "" ? "none" : "")};
  //text-transform: uppercase;
  &:hover {
    background-color: var(--darkblue);
    transition: 0.2s all ease-in-out;
  }
`;

const SingleProtocolType = styled.div`
  padding: 10px;
  border-radius: 10px;
  background-color: var(--black);
  border: 1px solid var(--blue);
  margin-top: 5px;

  h4 {
    color: var(--blue);
  }
`;

export default SingleProtocol;
