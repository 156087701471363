const hero = {
  title: "Stake with Huginn",
  subtext:
    "   We offer; easy, secure, reliable, non-custodial staking services.",
};

const about = {
  title: "About",
  subtext:
    " Stake Huginn has a dedicated team of professionals from various branches including IT Experts, Ops Finance, blockchain enthusiasts, of which most have been in Blockchain since 2016.",
  sublighttext:
    " Huginn.Tech staking service is part of a much larger conglomeration under the Huginn Academy umbrella. Huginn Academy comprises of more than 30 staff, 20 social accounts and websites, 360 degrees blockchain education programs, live streams, and much more, with a vision to educate, spread awareness and promote decentralization.",
};

export { hero, about };
