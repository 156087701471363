import React from "react";
import styled from "styled-components";
import { hero } from "../datas/content";
import Video from "../assets/video/video.mp4";

const Hero = () => {
  return (
    <HeroSection id="hero">
      <HeroContainer>
        <HeroBg>
          <VideoBg autoPlay={true} muted loop src={Video} type="video/mp4" />
        </HeroBg>
        <HeroLeft>
          <HeroTitle>{hero.title}</HeroTitle>
          <HeroText>{hero.subtext}</HeroText>
          <HeroBtn href="#networks">Stake</HeroBtn>
        </HeroLeft>
      </HeroContainer>
    </HeroSection>
  );
};

const HeroSection = styled.section`
  height: 700px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--black);
  position: relative;
  z-index: 1;
`;

const HeroBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const VideoBg = styled.video`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
`;

const HeroContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 800px;
  width: 100%;
  padding: 3rem 0 3rem calc((100vw - 1300px) / 2);
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const HeroLeft = styled.div`
  display: flex;
  color: var(--white);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 5rem 2rem;
  z-index: 1;
`;

const HeroTitle = styled.h1`
  margin-top: 2rem;
  font-size: 4rem;
  color: var(--white);

  @media (max-width: 500px) {
    margin-top: 3rem;
    line-height: 1.1;
    font-size: 3.5rem;
  }
`;

const HeroText = styled.p`
  margin: 2rem 0;
  line-height: 1.1;
  font-size: 2rem;
  color: var(--white);

  @media (max-width: 500px) {
    line-height: 1;
    font-size: 24px;
  }
`;

const HeroBtn = styled.a`
  padding: 1rem 3rem;
  font-size: 1.3rem;
  border: 2px solid var(--blue);
  border-radius: 10px;
  outline: none;
  color: var(--white);
  background: var(--blue);
  cursor: pointer;

  &:hover {
    background: var(--black);
    transition: 0.3s all ease-in-out;
  }

  @media (max-width: 500px) {
    padding: 1rem 2rem;
    font-size: 1rem;
  }
`;

export default Hero;
